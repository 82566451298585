/* Side panel to customize the 3D model & environment (background) */

import "./styles/OptionsSide.css";
import { lightOrDark, colorMaterials, currentBG, currentPalette, prevBG, prevPalette, currCanopy, currStem, prevCanopy, prevStem, originalCanopy, originalStem, currVariation, originalVariation, prevVariation } from "./MyFunctions";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { FormControlLabel, FormGroup, Switch } from "@mui/material";
import { makeStyles } from "@material-ui/core";


export function SideOpt({ opt, openSide, iframe, modelType, data, currModel, setCurrType, forceUpdate, setForceUpdate, dc, modelMtls }) {
    var toShow = null;
    switch (opt) {
        // Background panel
        case "choose":
            toShow = <BGopt setCurrType={setCurrType} bgcolor={data.bgColors} iframe={iframe} openSide={openSide} />
            //setTitle("bgTitle");
            break;
        // Color palette (template) panel
        case "palette":
            toShow = <PaletteOpt forceUpdate={forceUpdate} setForceUpdate={setForceUpdate} setCurrType={setCurrType} dc={dc} data={data} iframe={iframe} openSide={openSide} />
            //setTitle("paletteTitle");
            break;
        // Customize all material panel
        case "individual":
            toShow = <MtlOpt modelMtls={modelMtls} forceUpdate={forceUpdate} setForceUpdate={setForceUpdate} setCurrType={setCurrType} currModel={currModel} iframe={iframe} dataColor={dc} modelType={modelType} openSide={openSide} />
            //setTitle("mtlTitle");
            break;
        case "options":
            toShow = <Options modelMtls={modelMtls} data={data} setCurrType={setCurrType} setForceUpdate={setForceUpdate} forceUpdate={forceUpdate} currModel={currModel} iframe={iframe} openSide={openSide} modelType={modelType} dataColor={dc} />
            //tmpNum = 0;
            //setTitle("mtlTitle");
            break;
        default:
            toShow = null
            break;
    }
    //console.log(opt);
    return toShow;
}
// BG options -----------------------------------------------
export function BGopt({ setCurrType, openSide, iframe, bgcolor }) {
    const { t } = useTranslation();
    const i = iframe.contentWindow;
    const [currBG, setCurrBG] = useState({ color: currentBG.color, hex: currentBG.hex });
    function validation(accept) {
        setCurrType()
        openSide(null);
        if (accept) {
            prevBG.color = currentBG.color;
            prevBG.hex = currentBG.hex;
        } else {
            currentBG.color = prevBG.color;
            currentBG.hex = prevBG.hex;
            i.postMessage({
                background: prevBG.hex,
            });
        }
    }
    return (
        <div className="BGopt">
            <h4>{t("chooseBG")}</h4>
            <div id="bgColors">
                {
                    bgcolor.map(bg => {
                        return <BgBtn currBG={currBG} setCurrBG={setCurrBG} key={bg.id} iframe={i} color={bg.hex} nameEN={bg.name_en} nameFR={bg.name_fr} />
                    })
                }
            </div>
            <div className="validation">
                <button onClick={() => validation(false)} className="cancel">{t("cancel")}</button>
                <button onClick={() => validation(true)} className="accept">{t("accept")}</button>
            </div>
        </div>
    );
}

// Background button component
function BgBtn({ color, nameEN, nameFR, iframe, setCurrBG, currBG }) {
    const { t } = useTranslation();
    var isActive = currentBG.color === nameEN ? "active" : "";

    // Set background color
    function setBG(name, zeColor) {
        setCurrBG({ ...currBG, color: name, hex: zeColor });
        currentBG.color = name;
        currentBG.hex = zeColor;
        iframe.postMessage({
            background: zeColor,
        });
    }
    return (
        <button onClick={() => setBG(nameEN, color)} className={"bgBtn " + isActive}>
            <span style={{ backgroundColor: color }} className={"color" + lightOrDark(color) + " circle"}></span>
            <p>{t("customWord", { 'en': nameEN, "fr": nameFR })}</p>
        </button>
    );
}

//END BG options ---------------------------------------- END

// Palette options -----------------------------------------------
export function PaletteOpt({ setCurrType, openSide, iframe, data, forceUpdate, setForceUpdate, dc }) {
    const { t } = useTranslation();
    const i = iframe.contentWindow;
    const [currPalette, setCurrPalette] = useState({ id: currentPalette.id, code: currentPalette.code });


    function validation(accept) {
        setCurrType()
        openSide(null);
        if (accept) {
            prevPalette.id = currentPalette.id;
            prevPalette.code = currentPalette.code;

            //save palette to previous color
            colorMaterials.prev.exterior = currentPalette.code[3];
            colorMaterials.prev.exterior2 = currentPalette.code[3];
            colorMaterials.prev.exterior3 = currentPalette.code[3];
            colorMaterials.prev.interior = currentPalette.code[2];
            colorMaterials.prev.canopy = currentPalette.code[1];
            colorMaterials.prev.stem = currentPalette.code[0];

            i.postMessage({
                acceptMaterials: true,
            });
        } else {
            //save palette to current color
            colorMaterials.curr.exterior = colorMaterials.prev.exterior;
            colorMaterials.curr.exterior2 = colorMaterials.prev.exterior2;
            colorMaterials.curr.exterior3 = colorMaterials.prev.exterior3;
            colorMaterials.curr.interior = colorMaterials.prev.interior;
            colorMaterials.curr.canopy = colorMaterials.prev.canopy;
            colorMaterials.curr.stem = colorMaterials.prev.stem;

            currentPalette.id = prevPalette.id;
            currentPalette.code = prevPalette.code;
            i.postMessage({
                acceptMaterials: false,
            });
        }
    }
    return (
        <div className="BGopt">
            <h4>{t("choosePalette")}</h4>
            <div id="bgColors">
                {
                    data.colorPalette.map(colors => {
                        return (
                            colors.archived.includes("false") && <PaletteBtn
                                id={colors.id}
                                key={colors.id}
                                iframe={i}
                                activePalette={currPalette}
                                parent={setCurrPalette}
                                colors={dc}
                                palette={colors}
                                nameEN={colors.name_en}
                                nameFR={colors.name_fr}
                                forceUpdate={forceUpdate}
                                setForceUpdate={setForceUpdate}
                            />
                        )
                    })
                }
            </div>
            <div className="validation">
                <button onClick={() => validation(false)} className="cancel">{t("cancel")}</button>
                <button onClick={() => validation(true)} className="accept">{t("accept")}</button>
            </div>
        </div>
    );
}



// Palette button component
function PaletteBtn({ colors, palette, id, nameEN, nameFR, activePalette, parent, iframe, forceUpdate, setForceUpdate }) {
    const { t } = useTranslation();
    var isActive = currentPalette.id === id ? "active" : "";
    const rstBtn = document.getElementById("reset");

    function findColor(id) {
        var color;
        colors.map(c => {
            if (c.id === id) {
                color = c;
            }
            return false;
        })
        return color;
    }

    // Set palette color
    function setPalette(id, code) {
        setForceUpdate(!forceUpdate);
        parent({ ...activePalette, id: id, code: code });
        currentPalette.id = id;
        currentPalette.code = code;

        rstBtn.style.display = "block"
        //save palette to current color
        colorMaterials.curr.exterior = currentPalette.code[3];
        colorMaterials.curr.exterior2 = currentPalette.code[3];
        colorMaterials.curr.exterior3 = currentPalette.code[3];
        colorMaterials.curr.interior = currentPalette.code[2];
        colorMaterials.curr.canopy = currentPalette.code[1];
        colorMaterials.curr.stem = currentPalette.code[0];


        iframe.postMessage({
            templateMaterials: code,
        });
    }
    const stemC = findColor(palette.stem);
    const canopyC = findColor(palette.canopy);
    const interiorC = findColor(palette.interior);
    const exteriorC = findColor(palette.exterior);
    return (
        <button onClick={() => setPalette(id, [stemC.code, canopyC.code, interiorC.code, exteriorC.code])} className={"bgBtn " + isActive}>
            <span style={{ backgroundColor: stemC.hex }} className={"color" + lightOrDark(stemC.hex) + " circle palette"}></span>
            <span style={{ backgroundColor: canopyC.hex }} className={"color" + lightOrDark(canopyC.hex) + " circle palette"}></span>
            <span style={{ backgroundColor: interiorC.hex }} className={"color" + lightOrDark(interiorC.hex) + " circle palette"}></span>
            <span style={{ backgroundColor: exteriorC.hex }} className={"color" + lightOrDark(exteriorC.hex) + " circle palette"}></span>
            <p>{t("customWord", { 'en': nameEN, "fr": nameFR })}</p>
        </button>
    );
}

//END Palette options ---------------------------------------- END

// MTL options -----------------------------------------------
export function MtlOpt({ setCurrType, openSide, modelType, dataColor, iframe, currModel, forceUpdate, setForceUpdate, modelMtls }) {
    const { t } = useTranslation();
    const [toggles, setToggles] = useState([]);
    const i = iframe.contentWindow;
    const rstBtn = document.getElementById("reset");

    //var dc = structuredClone(dataColor);
    //console.log(modelMtls)

    useEffect(() => {
        setToggles(modelType);
    }, [modelType]);

    //setToggles(modelType);
    const [toggledParent, setToggledParent] = useState();
    function toggleParent(num) {
        if (toggledParent === num) {
            setToggledParent();
        } else {
            setToggledParent(num);
        }
    }
    function validation(accept) {
        setToggledParent();

        var tmp = 0;
        Object.keys(colorMaterials.curr).map(toggle => {
            //console.log(colorMaterials);
            tmp++;
            if (accept) {
                if (tmp === 1) {
                    rstBtn.style.display = "block"
                    currentPalette.id = null;
                    currentPalette.code = null;
                    prevPalette.id = currentPalette.id;
                    prevPalette.code = currentPalette.code;
                    i.postMessage({
                        acceptMaterials: true,
                    });
                }
                return colorMaterials.prev[toggle] = colorMaterials.curr[toggle];
            } else {
                var lastMtl = colorMaterials.prev[toggle] === undefined ? colorMaterials.default[toggle] : colorMaterials.prev[toggle];
                if (tmp === 1) {
                    i.postMessage({
                        acceptMaterials: false,
                    });

                }
                colorMaterials.curr[toggle] = lastMtl;
                return false;
            }

        })
        openSide(null);
        setCurrType();
    }
    const includeUB = currStem.data !== null ? currStem.data.code?.includes("UB") : false;
    const haveTwoStem = currModel.build.data.lampshadeNumber > 1;

    return (
        <div className="mtlOpt">
            <h4>{t("mtlTitle")}</h4>
            {toggles.slice(0).map((toggle) => {
                var convertedType = toggle.includes('wire') || toggle.includes('terrazzo') || toggle.includes('glass') ? "other" : "coating";
                var currToggled = toggle === toggledParent ? '' : 'toggled';
                const isStem = toggle === "stem" ? includeUB ? haveTwoStem ? true : false : true : true;
                const isGlass = !toggle.includes("glass");
                // console.log(toggle);
                //const moon = currModel.build.model.includes('MOON')?"":toggle
                return (
                    isStem && isGlass && <ToggleBtn
                        iframe={iframe}
                        dataColor={dataColor}
                        parentFunction={() => toggleParent(toggle)}
                        isToggled={currToggled}
                        key={toggle}
                        type={convertedType}
                        name={toggle}
                        currModel={currModel}
                        forceUpdate={forceUpdate}
                        setForceUpdate={setForceUpdate}
                        modelMtls={modelMtls[toggle]}
                    />

                )
            })}
            <div className="validation">
                <button onClick={() => validation(false)} className="cancel">{t("cancel")}</button>
                <button onClick={() => validation(true)} className="accept">{t("accept")}</button>
            </div>
        </div>
    );
}

function ToggleBtn({ name, type, isToggled, parentFunction, dataColor, iframe, currModel, forceUpdate, setForceUpdate, modelMtls }) {
    const { t } = useTranslation();
    const num = name.replace(/^\D+/g, '');
    const nameC = name.replace(/[0-9]/g, '');
    const i = iframe.contentWindow;
    var dc = structuredClone(dataColor);
    // console.log(name, dc);

    const sortMtl = ["SAME_EXT", "SAME_STEM"];
    //console.log("avant",dc);
    dc.sort(function (a, b) {
        //console.log(a.code);
        //return a.hex < b.hex ? 1 :-1;
        return sortMtl.indexOf(a.code) - sortMtl.indexOf(b.code) ? -1 : 1;

    });
    //console.log("apres",dc);


    const [activeIndex, setActive] = useState(colorMaterials.curr[name]);
    //console.log(colorMaterials.default);
    function setMtlActive(code) {
        setForceUpdate(!forceUpdate)
        setActive(code);
        colorMaterials.curr[name] = code;
        i.postMessage({
            material: { type: name, color: code },
        });
        //console.log(name,code);
    }
    var currentSelected = activeIndex === undefined ? colorMaterials.default[name] : activeIndex;
    function findColor() {
        var color = { name_en: "not valid", name_fr: "non valide", hex: "#404404" };
        dc.map(c => {
            if (c.code === currentSelected) {
                color = c;
            }
            return false;
        })
        return color;
    }
    function colorByCode(code) {
        var val = null;
        dc.map((el) => {
            if (el.code === code) {
                val = el;
                return false;
            }
            return false;
        });
        return val;
    }
    const zeColor = findColor();
    //console.log(colorMaterials.curr.exterior);
    const extColor = colorMaterials.curr.exterior !== undefined ? colorByCode(colorMaterials.curr.exterior).hex : colorByCode(colorMaterials.default.exterior).hex;
    const stemColor = colorMaterials.curr.stem !== undefined ? colorByCode(colorMaterials.curr.stem).hex : colorByCode(colorMaterials.default.stem).hex;
    const goodColor = zeColor.code === "SAME_EXT" ? extColor : zeColor.code === "SAME_STEM" ? stemColor : zeColor.hex;

    var nameC2;
    var simpleFinish = false;
    var terraCorrected = nameC;

    //console.log(currModel.build.model);
    if (nameC.includes('terrazzo')) {
        if (nameC.includes('terrazzobase')) {
            nameC2 = "terrazzo"
            terraCorrected = "stonebase";
        } else if (nameC.includes('terrazzo')) {
            terraCorrected = "stone";
        }
    } else if (nameC.includes('glass')) {
        if (nameC.includes('glassint')) {
            nameC2 = "glass color"
        } else {
            nameC2 = "glass finish"
            if (currModel.lampshade.model.includes("COPAG")) {
                simpleFinish = true;
                // console.log("fasf");
            }
        }
    } else if (nameC.includes('canopy')) {
        if (currModel.build.model.includes('AK')) {
            nameC2 = "canopy AK"
        } else {
            nameC2 = nameC
        }
    } else if (nameC.includes('stem')) {
        if (currModel.build.model.includes('AK')) {
            nameC2 = "stem AK"
        } else {
            nameC2 = nameC
        }
    } else if (nameC.includes('suspension')) {
        nameC2 = "cablehardware"
    } else {
        nameC2 = nameC
    }



    return (
        <div data-type={type} className={"toggleMenu " + isToggled}>
            <div onClick={parentFunction} className="topToggleParent">
                <p>{t(terraCorrected) + " " + num}</p>
                <div className="currentColor"><p>{t("customWord", { 'en': zeColor.name_en, "fr": zeColor.name_fr })}</p><span style={{ backgroundColor: goodColor }} className={"circle color" + lightOrDark(goodColor)}></span><span className="arrow"></span></div>
            </div>
            <div className="bottomToggleParent">

                {dc.map((mtlButton, i) => {
                    // console.log(modelMtls, mtlButton.id);
                    if (modelMtls?.includes(mtlButton.id)) {
                        const canoColor = colorMaterials.curr.exterior ? colorMaterials.curr.exterior : colorMaterials.default.exterior;
                        const stemColor = colorMaterials.curr.stem ? colorMaterials.curr.stem : colorMaterials.default.stem;
                        const mtlButtonColor = mtlButton.code === "SAME_EXT" ? colorByCode(canoColor).hex : mtlButton.code === "SAME_STEM" ? colorByCode(stemColor).hex : mtlButton.hex;
                        if (!simpleFinish || (!mtlButton.code.includes("PINEAPPLE") && !mtlButton.code.includes("SWIRL"))) {
                            var svg = <svg xmlns="http://www.w3.org/2000/svg" width="50" height="29" viewBox="0 0 35 20">
                                <path id="Combined_Shape" data-name="Combined Shape" d="M35,20h0Zm0,0H0A17.543,17.543,0,0,1,10,4.68V0h15.46V4.9A17.545,17.545,0,0,1,35,20Z" fill={mtlButtonColor} />
                            </svg>;

                            if (type !== "coating") {
                                svg = '';
                            }
                            //console.log(window[type]);
                            var ii = mtlButton.code === currentSelected ? ' active' : '';
                            const isBraided = mtlButton.code.slice(0, 2) === "T.";
                            //console.log(name);
                            const showWire = name === "wire" ? currModel.build.data.wireopt === 'simple' ? !isBraided : currModel.build.data.wireopt === 'braided' ? isBraided : true : true;
                            const imgBG = mtlButton.image === "true" ? "url(./images/elements/thumbnails/" + mtlButton.code + ".jpg)" : "";
                            const isImgBG = mtlButton.image === "true" ? " image" : "";
                            //console.log(mtlButton.archived);
                            const colorArchived = mtlButton.archived == undefined ? true : mtlButton.archived.includes("false");
                            //console.log(imgBG)
                            return (
                                showWire && colorArchived && <button
                                    data-color={mtlButton.hex}
                                    className={"mtlBtn" + ii + " color" + lightOrDark(mtlButtonColor) + isImgBG}
                                    onClick={() => setMtlActive(mtlButton.code)}
                                    style={{ backgroundColor: mtlButton.hex, backgroundImage: imgBG }}
                                    key={i}
                                    data-code={mtlButton.code}
                                    data-type={name}
                                    data-name={t("customWord", { 'en': mtlButton.name_en, "fr": mtlButton.name_fr })}>
                                    {svg}
                                </button>
                            )
                        }
                        return false;
                    }
                    return false;
                }
                )}
            </div>
        </div>
    );
}
// ------------------------------------------------------------------------------------------------------------------------------- OPTIONS MENU
var tmpNum = 0;
function Options(props) {
    const { t } = useTranslation();
    const i = props.iframe.contentWindow;
    const [toggles, setToggles] = useState(props.modelType[0]);
    const [toggleCano, setToggleCano] = useState(currCanopy.data);
    const [toggleStem, setToggleStem] = useState(currStem.data);
    const [toggleVariation, setToggleVariation] = useState(currVariation.data);
    const [stemUB, setStemUB] = useState(false);
    //console.log(currStem.data);
    const includeUB = currStem.data !== null ? currStem.data.code?.includes("UB") : false;
    const [isUB, setIsUB] = useState(includeUB);
    const canoData = props.currModel.build.data.canopy;
    const stemData = props.currModel.build.data.stem;
    const variationData = props.currModel.build.data.modeloptions;
    const variationLength = () => {
        var rtn = [];
        variationData.map((el) => {
            if (el.lampshades.includes(props.currModel.lampshade?.id)) {
                rtn.push(el)
            }
        })
        return rtn
    }
    // variationData
    // console.log(variationLength());
    canoData.sort();
    stemData.sort();

    if (tmpNum === 0) {
        //console.log("YOOO");
        prevCanopy.data = currCanopy.data;
        prevStem.data = currStem.data;
        prevVariation.data = currVariation.data;
        originalCanopy.data = currCanopy.data;
        originalStem.data = currStem.data;
        originalVariation.data = currVariation.data;
        tmpNum++;
    }



    //console.log(props.currModel);
    const rstBtn = document.getElementById("reset");
    function validation(accept) {
        props.setCurrType()
        props.openSide(null);
        //console.log(accept)
        if (accept) {
            prevCanopy.data = currCanopy.data;
            prevStem.data = currStem.data;
            prevVariation.data = currVariation.data;
            var tmp = 0;
            Object.keys(colorMaterials.curr).map(toggle => {
                //console.log(colorMaterials);
                tmp++;
                if (accept) {
                    if (tmp === 1) {
                        rstBtn.style.display = "block"
                        currentPalette.id = null;
                        currentPalette.code = null;
                        prevPalette.id = currentPalette.id;
                        prevPalette.code = currentPalette.code;
                        /*i.postMessage({
                            acceptMaterials: true,
                        });*/
                    }
                    return colorMaterials.prev[toggle] = colorMaterials.curr[toggle];
                } else {
                    var lastMtl = colorMaterials.prev[toggle] === undefined ? colorMaterials.default[toggle] : colorMaterials.prev[toggle];
                    if (tmp === 1) {
                        i.postMessage({
                            acceptMaterials: false,
                        });

                    }
                    colorMaterials.curr[toggle] = lastMtl;
                    return false;
                }

            })

            i.postMessage({
                acceptMaterials: true,
            });
        } else {
            //console.log(prevCanopy.data);

            setToggleCano(prevCanopy.data);
            setToggleStem(prevStem.data);
            setToggleVariation(prevVariation.data)
            currCanopy.data = prevCanopy.data;
            currStem.data = prevStem.data;
            currVariation.data = prevVariation.data;
            //console.log(prevVariation.data);


            Object.keys(colorMaterials.curr).map(toggle => {
                //console.log(colorMaterials);
                tmp++;
                var lastMtl = colorMaterials.prev[toggle] === undefined ? colorMaterials.default[toggle] : colorMaterials.prev[toggle];
                colorMaterials.curr[toggle] = lastMtl;
                return false;
            })

            i.postMessage({
                acceptMaterials: false,
            });
            if (prevStem.data !== null) {
                i.postMessage({
                    stem: { height: prevStem.data?.code }
                });
            }
            if (prevCanopy.data !== null) {
                i.postMessage({
                    canopy: { type: prevCanopy.data.code }
                });
            }
            if (variationData.length > 0) {
                i.postMessage({
                    variation: prevVariation.data?.code
                });
            }

        }
        tmpNum = 0;
    }
    function zeToggle(num) {
        if (toggles === num) {
            setToggles();
        } else {
            setToggles(num);
        }
    }

    function foundByID(type, id) {
        var rtn;
        props.data[type].map((e, i) => {
            if (e.id === id) {
                rtn = e;
            }
            return false;
        })
        return rtn;
    }
    function findStemByCode(code) {
        var rtn;
        props.data.stem.map((e, i) => {
            if (e.code === code) {
                rtn = e;
            }
            return false;
        })
        return rtn;
    }

    function setUB(setter) {
        setIsUB(setter);
        const stem = String(toggleStem.code);
        var zeUB;
        if (setter) {
            zeUB = findStemByCode(stem + "UB");
        } else {
            zeUB = findStemByCode(stem.replace("UB", ""));
        }
        setToggleStem(zeUB);
        setStem(zeUB)
        //console.log(isUB);
    }

    const canopyToggled = toggles === 1 ? "" : "toggled";
    const stemToggled = toggles === 2 ? "" : "toggled";
    const variationToggled = toggles === 3 ? "" : "toggled";

    const useStyles = makeStyles({
        switch: {
            "& .Mui-checked": {
                color: "#005aa6",
            },
            "& .Mui-checked+.MuiSwitch-track": {
                backgroundColor: "#005aa6"
            },
            "& .MuiSwitch-track": {
                backgroundColor: "#888"
            },
            "& .css-jsexje-MuiSwitch-thumb": {
                boxShadow: "0px 0px 5px 0px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 22%)"
            }
        }
    });

    function setStem(model) {
        setToggleStem(model)
        i.postMessage({
            stem: { height: model.code },
        });
    }
    function setCanopy(model) {
        setToggleCano(model)
        i.postMessage({
            canopy: { type: model.code },
        });
    }
    function setVariation(model) {
        setToggleVariation(model)
        currVariation.data = model;
        i.postMessage({
            variation: model.code,
        });
    }



    const classes = useStyles();
    const [toggleGlass, setToggleGlass] = useState([]);
    useEffect(() => {
        setToggleGlass(props.modelType);
        //console.log(props.modelType);
    }, [props.modelType]);

    return (
        <div className="optionsCustomization">
            <h4>{t("mtlTitle")}</h4>
            { // Adding all meterials & ball options
                toggleGlass.slice(0).map((toggle, index) => {
                    var convertedType = toggle.includes('wire') || toggle.includes('terrazzo') || toggle.includes('glass') ? "other" : "coating";
                    var currToggled = toggle === toggles ? '' : 'toggled';
                    const isGlass = toggle.includes("glass");

                    // console.log(toggle, props.modelMtls)
                    /*if(index == 0){
                        toggle
                    }*/
                    return (
                        <ToggleBtn
                            iframe={props.iframe}
                            dataColor={props.dataColor}
                            parentFunction={() => zeToggle(toggle)}
                            isToggled={currToggled}
                            key={toggle}
                            type={convertedType}
                            name={toggle}
                            currModel={props.currModel}
                            forceUpdate={props.forceUpdate}
                            setForceUpdate={props.setForceUpdate}
                            modelMtls={props.modelMtls[toggle]}
                        />

                    )
                })}

            {/* {(canoData.length > 1 || stemData.length > 1 || variationLength().length > 1) && <h4>{t("customizeCreation")}</h4>} */}
            {canoData.length > 1 && <div data-type={"canopyType"} className={"toggleMenu " + canopyToggled}>
                {/*<div onClick={() => zeToggle(1)} className="topToggleParent">
                    <p>{t("canopyType")}</p>
                    <div className="currentColor"><p>{t("customWord", { 'en': toggleCano.name_en, "fr": toggleCano.name_fr })}</p><span></span><span className="arrow"></span></div>
                </div>*/}
                <ToggleParent
                    setToggle={zeToggle}
                    toggle={1}
                    name={{ "en": toggleCano.name_en, "fr": toggleCano.name_fr }}
                    title={t("canopyType")}
                />
                <div className="bottomToggleParent">
                    {canoData.map((canopyBtn, i) => {
                        {
                            const cano = foundByID("canopy", canopyBtn)
                            const active = toggleCano.code === cano.code ? "active" : "";
                            return <button
                                className={"mtlBtn canopy " + active}
                                onClick={() => active == "" && setCanopy(cano)}
                                key={i}
                                style={{ backgroundImage: "url(./images/elements/canopies/" + cano.code + ".png)" }}
                                data-code={cano.code}
                                data-type={"canopy"}
                                data-name={t("customWord", { 'en': cano.name_en, "fr": cano.name_fr })}>
                            </button>
                        }
                    })}
                </div>
            </div>}
            {stemData.length > 1 && <div data-type={"stemLength"} className={"toggleMenu " + stemToggled}>
                {/*<div onClick={() => zeToggle(2)} className="topToggleParent">
                    <p>{t("stemLength")}</p>
                    <div className="currentColor"><p>{t("customWord", { 'en': toggleStem.name_en, "fr": toggleStem.name_fr })}</p><span></span><span className="arrow"></span></div>
                </div>*/}
                <ToggleParent
                    setToggle={zeToggle}
                    toggle={2}
                    name={{ "en": toggleStem.name_en, "fr": toggleStem.name_fr }}
                    title={t("stemLength")}
                />
                <div className="bottomToggleParent">
                    <div className="bottomToggleButtons">
                        {stemData.map((stemBtn, i) => {
                            {
                                const stem = foundByID("stem", stemBtn)
                                if (!stem.code.includes("UB")) {
                                    const active = String(toggleStem.code).replace("UB", "") === stem.code ? "active" : "";
                                    const stemUB = isUB ? findStemByCode(stem.code + "UB") : stem;
                                    return <button
                                        className={"mtlBtn stem " + active}
                                        onClick={() => active == "" && setStem(stemUB)}
                                        key={i}
                                        data-code={stem.code}
                                        data-type={"stem"}
                                        data-name={t("customWord", { 'en': stem.name_en, "fr": stem.name_fr })}>
                                        {t("customWord", { 'en': stem.name_en, "fr": stem.name_fr })}
                                    </button>
                                } else {
                                    if (!stemUB) {
                                        setStemUB(true);
                                    }
                                }
                            }
                        })
                        }
                    </div>
                    {stemUB &&
                        <div className="brassOpt">
                            <FormGroup>
                                <FormControlLabel
                                    value="UB"
                                    control={
                                        <Switch
                                            onChange={() => setUB(!isUB)}
                                            checked={isUB}
                                        />
                                    }
                                    label={t("ubOpt")}
                                    labelPlacement="start"
                                    sx={{ margin: 0 }}
                                    className={classes.switch}
                                />
                            </FormGroup>
                        </div>
                    }
                </div>
            </div>}
            {
                //const modOpt = props.currModel.build.data.modeloptions;
                variationLength().length > 1 && props.currModel.lampshade.id &&
                <div data-type={"variations"} className={"toggleMenu " + variationToggled}>
                    <ToggleParent
                        setToggle={zeToggle}
                        toggle={3}
                        name={{ "en": toggleVariation.name, "fr": toggleVariation.name }}
                        title={"variations"}
                    />
                    <div className="bottomToggleParent">
                        {variationLength().map((variationBtn, i) => {
                            {
                                const variation = variationBtn;
                                const active = toggleVariation.code === variation.code ? " active" : "";
                                //const disabled = !variation.lampshades.includes(props.currModel.lampshade?.id);
                                //const isDisabled = disabled?
                                return <button
                                    className={"mtlBtn variation" + active}
                                    onClick={() => active == "" && setVariation(variation)}
                                    key={i}
                                    data-code={variation.code}
                                    data-type={"variation"}
                                    data-name={t("customWord", { 'en': variation.name, "fr": variation.name })}>
                                    {t("customWord", { 'en': variation.name, "fr": variation.name })}
                                </button>

                            }
                        })
                        }
                    </div>
                </div>
            }
            <div className="validation">
                <button onClick={() => validation(false)} className="cancel">{t("cancel")}</button>
                <button onClick={() => validation(true)} className="accept">{t("accept")}</button>
            </div>
        </div>
    );
}

function ToggleParent(props) {
    const { t } = useTranslation();
    return (
        <div onClick={() => props.setToggle(props.toggle)} className="topToggleParent">
            <p>{props.title}</p>
            <div className="currentColor"><p>{t("customWord", { 'en': props.name.en, "fr": props.name.fr })}</p><span></span><span className="arrow"></span></div>
        </div>
    )
}