import './styles/SideMenu.css';
import LampEl from "./LampElement";
import { useTranslation } from "react-i18next";
import { useState } from 'react';
import LoadingScreen from './LoadingScreen';

function SideMenu({ currentBGid, currModel, parentModel, data, iframe, filtered, loadScreen, setLoadScreen, lampshadeRef, buildRef, mobile, isRequired, setRequired, preSubmit, resetAll, isResetAll, setIsResetAll }) {
    //const elData = await data;
    const { t } = useTranslation();
    /*data.builds.sort(function(a, b){
        return bAr.indexOf(a.name) - bAr.indexOf(b.name);
    });  */            // Array of all builds
    /* data.builds.sort((a, b) =>{
         return a.name-b.name;
     }); */             // Array of all builds
    data.builds.sort((a, b) => Number(String(a.name).replace(/\D/g, '')) > Number(String(b.name).replace(/\D/g, '')) ? 1 : -1);               // Array of all builds
    /*data.builds.sort((a,b) =>{
        if(a.name.includes("AK") && b.name.includes("AK")){
            //if(a.name > b.name){
                return 1
            //}
        }else if(!a.name.includes("AK") && !b.name.includes("AK")){
            return -1
        }
        return 0
    });*/
    //console.log(filtered);

    const sortedBuild = () => {
        var tmpLA = [];
        var tmpAK = [];
        var tmpMerged;

        data.builds.map((e) => {
            if (e.name.includes("LA")) {
                tmpLA.push(e);
            } else if (e.name.includes("AK")) {
                tmpAK.push(e);
            }
        })
        tmpMerged = tmpLA.concat(tmpAK);
        return tmpMerged;
    }
    //console.log(sortedBuild());
    data.lampshades.sort((a, b) => a.name > b.name ? 1 : -1);           // Array of all lampshades
    data.build_categories.sort((a, b) => a.name_en > b.name_en ? 1 : -1);     // Array of all build categories    

    const [queryBuild, setQueryBuild] = useState("");
    const [queryBuildSelect, setQueryBuildSelect] = useState("");
    const [queryLampshade, setQueryLampshade] = useState("");

    // Filters (Search) queries

    const searchL = data.lampshades.filter(el => el.archived.includes("false")).filter(el => el.name.toLowerCase().includes(queryLampshade)).filter(el => filtered.lampshade.includes(el.id));
    const searchB = sortedBuild().filter(el => el.archived.includes("false")).filter(el => el.category.includes(queryBuildSelect)).filter(el => filtered.build.includes(el.id)).filter(el => el.name.toLowerCase().includes(queryBuild)).filter(el => currentBGid === null ? el : el.background.includes(currentBGid));


    const reqBuild = isRequired.build || !preSubmit ? "" : "required";
    const reqLampshade = isRequired.lampshade || !preSubmit ? "" : "required";

    const [acceptOpened, setAcceptOpened] = useState(false);

    function acceptReset(resetBool) {
        if (resetBool) {
            resetAll(true);
        }
        setAcceptOpened(false);
    }

    const isReset = isResetAll ? " reset" : "";

    //console.log({queryBuildSelect})
    return (
        <div className={mobile + isReset} id="sidemenu">
            {loadScreen && <LoadingScreen />}
            <div className={"col " + reqBuild}>
                <div className="sidetop not">
                    <select id="LAtype" onChange={(e) => setQueryBuildSelect(e.target.value)}>
                        {
                            data.build_categories.map((cat) => {
                                const n = cat.name_en === 'All builds' ? "" : cat.name_en;
                                return <option
                                    key={cat.id}
                                    value={n}>
                                    {t("customWord", { 'en': cat.name_en, "fr": cat.name_fr })}
                                </option>
                            })
                        }
                    </select>
                    <div className="arrow"></div>
                </div>
                <div className="sidetop not">
                    <input
                        type="text"
                        placeholder={t("typesearch")}
                        onChange={(e) => setQueryBuild(e.target.value.toLowerCase())}
                        value={queryBuild}
                    />
                    {queryBuild !== "" && <div onClick={() => setQueryBuild("")} className="rstInput"></div>}
                </div>
                {/* Show all builds + filter by name */}
                <div className="lamp-elements-parent">
                    {searchB.length > 0 ?
                        searchB.map((element) => {
                            //console.log(element.code)
                            //var goodObj = currModel.build.model !== undefined? element.lampshades.includes(element.id);
                            //var validFilter = filtered.build.length && filtered.lampshade.length ? filtered.build.includes(element.id):true;
                            var filter = filtered.build.includes(element.id) ? "" : "filtered";
                            //console.log(element);
                            return <LampEl
                                key={element.id}
                                iframe={iframe}
                                parentModel={parentModel}
                                currModel={currModel}
                                type="build"
                                el={element}
                                filtered={filter}
                                setLoadScreen={setLoadScreen}
                                loadScreen={loadScreen}
                                zeref={(ele) => buildRef.current[element.id] = ele}
                                setRequired={setRequired}
                                isRequired={isRequired}
                                setResetAll={setIsResetAll}
                            />
                        }) :
                        <div className='emptyCol'>{t('emptySearch') + " \"" + queryBuild + "\""}</div>
                    }
                </div>
            </div>
            <div className={"col " + reqLampshade}>
                <div className="sidetop">{t("lampshade")}</div>
                <div className="sidetop not">
                    <input
                        type="text"
                        placeholder={t("typesearch")}
                        onChange={(e) => setQueryLampshade(e.target.value.toLowerCase())}
                        value={queryLampshade}
                    />
                    {queryLampshade !== "" && <div onClick={() => setQueryLampshade("")} className="rstInput"></div>}
                </div>
                {/* Show all lampshades + filter by name */}
                <div className="lamp-elements-parent">
                    {searchL.length > 0 ?
                        searchL.map((element) => {
                            //var filtered = currModel.lampshade.model !== undefined? element.lampshades.includes(element.id):"";
                            //console.log(element);
                            //var validFilter = filtered.build.length && filtered.lampshade.length ? filtered.lampshade.includes(element.id):true;
                            var filter = filtered.lampshade.includes(element.id) ? "" : "filtered";
                            return <LampEl
                                key={element.id}
                                iframe={iframe}
                                parentModel={parentModel}
                                currModel={currModel}
                                type="lampshade"
                                el={element}
                                filtered={filter}
                                setLoadScreen={setLoadScreen}
                                loadScreen={loadScreen}
                                zeref={(ele) => lampshadeRef.current[element.id] = ele}
                                setRequired={setRequired}
                                isRequired={isRequired}
                                setResetAll={setIsResetAll}
                            />
                        }) : filtered.lampshade.length > 0 ?
                            <div className='emptyCol'>{t('emptySearch') + " \"" + queryLampshade + "\""}</div>
                            :
                            <div className='emptyCol'>{t('emptyCombination')}</div>
                    }

                </div>
            </div>

            {isResetAll && <div id='resetCreation' className={acceptOpened ? "opened":""}>
                <div onClick={() => !acceptOpened && setAcceptOpened(true)}>{t("resetCreation")}</div>
                <div>
                    <div onClick={() => acceptOpened && acceptReset(false)}>{t("cancel")}</div>
                    <div onClick={() => acceptOpened && acceptReset(true)}>{t("accept")}</div>
                </div>
            </div>}
        </div>
    )

}
export default SideMenu;