import React, { useEffect, useRef, useState, useCallback } from "react";
import './styles/App.css';
import './styles/Mobile.css';
import TopBar from './TopBar';
import BottomBar from './BottomBar';
import SideMenu from './SideMenu';
import OptionTop from './OptionsTop';
import i18n from './i18n';
import LocaleContext from './LocaleContext';
import { useTranslation } from "react-i18next";
import { SideOpt } from "./OptionsSide";
import LoadingScreen from "./LoadingScreen";
import { bgValues, colorMaterials, currCanopy, currentBG, currentPalette, currStem, currVariation, prevBG, prevCanopy, prevPalette, prevStem, prevVariation, productID, sortOrder, sortOrderProduct } from "./MyFunctions";
import PrintPage from "./PrintPage";
//import { Chat } from "./Chat";
import RightOpt from "./RightOpt";
import Env3D from "./Env3D";
import PDF from "./PDF";
import Cart from "./Cart";
import OnBoarding from "./OnBoarding";
import Possibilities from "./Possibilities";
import SystemSecurityUpdateWarningRoundedIcon from '@mui/icons-material/SystemSecurityUpdateWarningRounded';
import axios from "axios";
//import {data} from  "./MyFunctions";

var setPurl = 0;
var setProductUrl = 0;
var variationNum = 0;
var variationNum2 = 0;
var dc = null;
var modelMtls = {};
const appVersion = "6.8.2"
/*var eventNumber = 0;
window.test = 'NICE';*/


function App() {

    const urlShort = "https://3d.luminaireauthentik.com"; // Latest
    // //const urlShort = "https://192-46-223-43.ip.linodeusercontent.com";
    // //const urlShort = "https://172-105-97-91.ip.linodeusercontent.com";
    const url = urlShort + ":3000/api/data";

    // const urlShort = "http://10.0.1.94";
    // const url = urlShort + ":4000/api/data";

    //const pathName = window.location.pathname;
    //console.log(pathName);
    const urlParams = new URLSearchParams(window.location.search);

    const [locale, setLocale] = useState(i18n.language);
    const { t } = useTranslation();
    const [data, setData] = useState([]);
    const [iframe, setIframe] = useState(null)
    //const iframe = document.getElementById('appIframe');
    const [productIframe, setProductIframe] = useState(null)
    //const productIframe = document.getElementById('productIframe');
    const [loadScreen, setLoadScreen] = useState(true);
    const [loadMtlScreen, setLoadMtlScreen] = useState(true);
    const [cart, setCart] = useState({ build: 'none', lampshade: 'none' });
    const [currentBGid, setCurrentBGid] = useState(null);
    //const [dc, setDC] = useState(null);

    const [forceUpdate, setForceUpdate] = useState(false);

    const [required, setRequired] = useState({ build: false, lampshade: false, options: false });
    const [preSubmit, setPreSubmit] = useState(false);



    // URL parameter
    /*const windowUrl = window.location.search;
    const params = new URLSearchParams(windowUrl);*/
    //const [pUrl,setPurl] = useState(0);
    const [code, setCode] = useState(['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '']);
    let urlCode = urlParams.get('code');
    let urlMode = urlParams.get('mode') ? urlParams.get('mode') : '';
    //var setPurl = 0;

    //const [title,setTitle] = useState("main"); // "main" = normal title | "bg" = Background color | "palette" = Color palette | "mtl" = material library

    const [err, handleErr] = useState(false);

    useEffect(() => {
        document.title = t("mainTitle");
        document.documentElement.setAttribute("lang", i18n.language);

        // Fetch all data from database
        if (!Object.keys(data).length) {
            fetch(url)
                .then(res => res.json())
                .then((json) => {
                    setData(json);
                    // getERPdata()
                    //console.log(dbWithPhotos(json));
                    urlMode.includes('product') && window.parent.postMessage({ apiDB: dbWithPhotos(json) }, "*"); // trigger event to parent for product page
                    urlParams.get('lang') && i18n.changeLanguage(urlParams.get('lang'));
                    // console.log(dbWithPhotos(json));
                })
                .catch(err => handleErr(true))
        }
    }, [setData, t, data]);

    function dbWithPhotos(db) {
        const currentUrl = urlShort + "/images/elements";
        var rtn = db;
        rtn.builds.map((b) => {
            b.imgUrl = currentUrl + "/builds/" + b.code + ".png"
        })
        rtn.lampshades.map((s) => {
            s.imgUrl = currentUrl + "/lampshades/" + s.code + ".png"
        })
        rtn.canopy.map((c) => {
            c.imgUrl = currentUrl + "/canopies/" + c.code + ".png"
        })
        rtn.colors.map((col) => {
            if (col.image === "true") {
                col.imgUrl = currentUrl + "/thumbnails/" + col.code + ".jpg"
            } else {
                col.imgUrl = null;
            }
        })
        rtn.background.map((bg) => {
            bg.imgUrl = urlShort + "/env3d/" + bg.code + ".jpg"

        })

        return rtn;
    }

    function makeCurrMtl(mtlM) {
        mtlM.map((el) => {
            if (colorMaterials.curr[el] === undefined) {
                colorMaterials.curr[el] = colorMaterials.default[el];
                //Object.assign(colorMaterials.curr[el],colorMaterials.default[el]);
            }
        })
    }

    // All events handlers
    const [mtl, setMtl] = useState([]);

    const OnModelBegin = () => {
        //console.log('model begin!');
        urlMode.includes('product') && window.parent.postMessage({ isLoading: 'true' }, "*"); // Components array for product page
        setLoadScreen(true)
    };

    //const [tmpModel,setTmpModel] = useState();

    // const [modelEndEvt, setModelEndEvt] = useState(null);

    // useEffect(() => {
    //     console.log(modelEndEvt)
    //     if (modelEndEvt !== null) {
    //         //OnModelEnd(modelEndEvt);
    //     }
    // }, [modelEndEvt]);

    const OnModelEnd = (evt) => {
        //console.log(evt.detail);
        //variationNum++;
        const e = evt.detail;
        var tmpArray = [...e.components];
        var tmpArray2 = [...e.components];
        //const index = tmpArray.indexOf("stem2");
        //tmpArray = tmpArray.splice(index,1);
        //tmpArray2 = tmpArray2.splice(index,1);
        tmpArray.sort(function (a, b) {
            return sortOrder.indexOf(a) - sortOrder.indexOf(b);
        });
        tmpArray2.sort(function (a, b) {
            return sortOrderProduct.indexOf(a) - sortOrderProduct.indexOf(b);
        });
        // if (urlCode === null) {
        const c = e.canopy === undefined && e.canopy === "none" ? {} : findPartByCode("canopy", e.canopy);
        const s = e.stem === undefined && e.stem === "none" ? {} : findPartByCode("stem", e.stem);
        //const v = e.stem === undefined && e.stem === "none" ? {} : findPartByCode("stem", e.stem);
        //console.log(model);
        currCanopy.data = c;
        currStem.data = s;
        //}
        //console.log(tmpArray);
        urlMode.includes('product') && window.parent.postMessage({ components: tmpArray2 }, "*")   // Components array for product page
        urlMode.includes('product') && window.parent.postMessage({ isLoading: 'false' }, "*");     // Components array for product page
        //if (e.components.length > 0 || urlCode !== null) {
        makeCurrMtl(tmpArray)
        setMtl(tmpArray);
        setLoadScreen(false)
        //}
        //console.log(variationNum)
        /**if(variationNum==1){
            //console.log("AFSfas")
            callVariation(model,i);
            variationNum = 0;
        }*/
        //callVariation(model,i);
        //console.log("END!");
        //setTmpModel(model.build);

        if (urlCode !== null) {
            if (setPurl === 0) {
                setPurl++;
            }
        }
        if (urlMode.includes('product')) {
            if (setProductUrl === 0) {
                setProductUrl++;
            }
        }
        //console.log('model end!');
    };

    const OnMaterialBegin = () => {
        //console.log('mtl begin!');
        setLoadMtlScreen(true)
    };
    const OnMaterialEnd = () => {
        //console.log('mtl end!');
        //if(mtl.length > 0 || urlCode !== null){
        setLoadMtlScreen(false);
        //}
        //console.log(loadScreen);
    };

    const [isResetAll, setIsResetAll] = useState(false);
    const [resetNum, setResetNum] = useState(0);

    const restBtnRef = useRef([]);

    function resetAll() {
        setOnMakingModel(true);
        setResetNum(1);

    }

    useEffect(() => {
        if (resetNum == 1) {
            var id = 0;
            const interval = setInterval(() => {
                if (id === 0) {
                    restBtnRef.current.click();
                    //tmpVariation = id;
                } else if (id === 1) {
                    buildRef.current[model.build.id]?.click();
                } else if (id === 2) {
                    lampshadeRef.current[model.lampshade.id]?.click();
                } else if (id === 3) {
                    setIsResetAll(false);
                    setCurrentBGid(null);
                    setMenuEnv3D(true);
                    iframe?.contentWindow.postMessage({
                        integration: { mode: 'normal', name: '' },
                    });
                    setOnMakingModel(false)
                    setResetNum(0)
                    clearInterval(interval)
                }
                id++;

            }, 50);

            return () => clearInterval(interval);
        }
    }, [resetNum]);



    // ----------------------------------------------------------------------- Print page
    const [sImg, setSimg] = useState('./images/logo.png');
    const [bImg, setBimg] = useState(false);

    const OnScreenshot = (evt) => {
        setBimg(true);
        setSimg(evt.detail);
    };

    useEffect(() => {
        //if(iframe !== null){
        //console.log(iframe);
        iframe?.contentWindow.addEventListener("OnModelBegin", OnModelBegin, false);
        iframe?.contentWindow.addEventListener("OnMaterialBegin", OnMaterialBegin, false);
        iframe?.contentWindow.addEventListener("OnModelEnd", OnModelEnd, false);
        iframe?.contentWindow.addEventListener("OnMaterialEnd", OnMaterialEnd, false);
        iframe?.contentWindow.addEventListener("OnScreenshot", OnScreenshot, false);
        //}
    }, [iframe]);


    // --------------------- PRODUCT PAGE Event ------------------------ //
    useEffect(() => {
        //if(iframe !== null){
        //console.log(productIframe);
        productIframe?.contentWindow.addEventListener("OnModelBegin", OnModelBegin, false);
        productIframe?.contentWindow.addEventListener("OnMaterialBegin", OnMaterialBegin, false);
        productIframe?.contentWindow.addEventListener("OnModelEnd", OnModelEnd, false);
        productIframe?.contentWindow.addEventListener("OnMaterialEnd", OnMaterialEnd, false);
        productIframe?.contentWindow.addEventListener("OnScreenshot", OnScreenshot, false);
        if (productIframe !== null) {
            window.addEventListener('message', function (e) {
                //console.log(e.data)
                if (e.data.material) { // set Materials
                    colorMaterials.curr[e.data.material.component] = e.data.material.material;
                    productIframe.contentWindow.postMessage({
                        material: {
                            type: e.data.material.component,
                            color: e.data.material.material
                        }
                    })
                } else if (e.data.focusComponent) { // set zoom (focus) on current component
                    productIframe.contentWindow.postMessage({ focusComponent: e.data.focusComponent }, "*");
                } else if (e.data.printPDF) {
                    printPage(true);
                    //window.parent.postMessage({apiDB:data}, "*");
                } else if (e.data.stem) {
                    //console.log(e.data.stem)
                    productIframe.contentWindow.postMessage({ stem: { height: e.data.stem.type }, });
                    //window.parent.postMessage({apiDB:data}, "*");
                } else if (e.data.canopy) {
                    //console.log(e.data.canopy)
                    productIframe.contentWindow.postMessage({ canopy: { type: e.data.canopy.type }, });
                    //window.parent.postMessage({apiDB:data}, "*");
                }
            }, false);

        }
        //productIframe?.console.log(productIframe?.contentWindow);
        //}
    }, [productIframe]);

    /*useEffect(()=>{
      if(iframe !== null){
        iframe.contentWindow.addEventListener("OnScreenshot", OnScreenshot, false);
        return () =>{
          iframe.contentWindow.removeEventListener("OnScreenshot", OnScreenshot);
        };
      }
    },[OnScreenshot]);*/


    window.onbeforeprint = function () {
        //onbeforeprint();
    }

    function printPage(force = false) {
        if (validCombination || force) {
            setPDFVisible(true);
            setCurrType()
            setCodeFromModel()
            setSideOpened(null);
            const i = urlMode.includes('product') ? document.getElementById("productIframe").contentWindow : document.getElementById("appIframe").contentWindow;
            i.postMessage({
                takePhoto: ''
            })
        }
    }

    function modelByCode(part, code) {
        var val = null;
        data[part].map((el) => {
            if (el.code === code) {
                val = el;
                return false;
            }
            return false;
        });
        return val;
    }

    // -------------- On start -------------- //
    useEffect(() => {
        if (setProductUrl === 1) {
            var id = 0;
            const ifr = productIframe.contentWindow;
            //productIframe?.contentWindow.postMessage({transparentBG:{bg:'true'}}, "*");
            const interval = setInterval(() => {
                if (id == 0) {
                    if (urlParams.get('build') !== null) {
                        setLoadScreen(true);
                        const b = modelByCode('builds', urlParams.get('build'));
                        //console.log(b);  
                        productID.build = b.id;
                        setModel(pmodel => ({ ...pmodel, build: { model: b.code, title: b.name, id: b.id, data: b } }));
                        ifr.postMessage({
                            build: urlParams.get('build'),
                        })
                        variationNum = 1;
                    }
                } else if (id == 1) {
                    if (urlParams.get('lampshade') !== null) {
                        setLoadScreen(true);
                        const l = modelByCode('lampshades', urlParams.get('lampshade'));
                        productID.lampshade = l.id;
                        setModel(pmodel => ({ ...pmodel, lampshade: { model: l.code, title: l.name, id: l.id, data: l } }));
                        ifr.postMessage({
                            lampshade: urlParams.get('lampshade'),
                        })
                        variationNum = 1;
                    }
                } else {

                    clearInterval(interval);
                    //console.log(model);
                }
                id++;
            }, 50);

            return () => clearInterval(interval);
        }
    }, [setProductUrl]);

    // END --------------------------------------------------------------------- Print page


    const [sideOpened, setSideOpened] = useState(null);
    const [model, setModel] = useState({ build: { model: "", title: "", id: null, data: null }, lampshade: { model: "", title: "", id: null, data: null } });
    const [b, setB] = useState(""); // set temp build value
    const [l, setL] = useState(""); // set temp lampshade value
    const [b2, setB2] = useState(""); // set temp build value
    const [l2, setL2] = useState(""); // set temp lampshade value
    const [filteredID, setFilteredID] = useState({ build: [], lampshade: [] });
    const [validCombination, setValidCombination] = useState(false);

    const [currType, setCurrType] = useState();

    function initFilters(isPro = false) {
        if (isPro) {
            const iframe = document.getElementById('productIframe')
            setProductIframe(iframe);
            const dragFrame = window.frames["productIframe"].contentDocument.getElementById("dragPage")
            //console.log(document.getElementById('appIframe'));
        } else {
            const iframe = document.getElementById('appIframe')
            setIframe(iframe);
            const dragFrame = window.frames["appIframe"].contentDocument.getElementById("dragPage")
            // console.log(window.frames["appIframe"].contentDocument.getElementById("loadingModel"))
            //dragFrame.style.backgroundColor = "#efefefd8";
        }

        var bA = [];
        var lA = [];
        data.builds.map((el) => {
            bA.push(el.id);
            return false;
        })
        data.lampshades.map((el) => {
            lA.push(el.id);
            return false;
        })
        setFilteredID({ ...filteredID, build: bA, lampshade: lA });


    }
    useEffect(() => {
        var i = iframe !== null ? iframe.contentWindow : "";
        validCombinationF();
        if (i !== "") {
            dc = structuredClone(data.colors);
            //console.log(dc);
            //setDC(structuredClone(data.colors));
            if (l !== model.lampshade.model) {
                //Object.assign(modelMtls, model.lampshade.data?.materials,model.lampshade.data?.materials);
                //console.log(modelMtls);
                mtlPrice(model);
                if (filteredID.lampshade.includes(model.lampshade.id)) {
                    const zeL = model.lampshade.model === "" ? "none" : model.lampshade.model;
                    setL(model.lampshade.model);
                    //console.log('post lampshade: ' + zeL);

                    i.postMessage({
                        lampshade: zeL,
                    })
                    variationNum = 1;
                    /*if (model.build.data?.stemheights.length > 0) {
                        model.build.data.stemheights.some((el) => {
                            if (el.lampshade == model.lampshade.id) {
                                console.log(el.stemHeight)
                                setStemTimer(el.stemHeight);
                                //callVariation(model,i);
                            }
                        })
                    }*/
                } else {
                    setL("none");
                    //console.log('post lampshade: NONE');
                    i.postMessage({
                        lampshade: "none",
                    })
                }

            }
            if (b !== model.build.model) {
                mtlPrice(model);

                //console.log(modelMtls);
                if (filteredID.build.includes(model.build.id)) {
                    const zeB = model.build.model === "" ? "none" : model.build.model;
                    setB(model.build.model);
                    if (colorMaterials.curr !== undefined) {
                        Object.keys(colorMaterials.curr).map(function (el) {
                            // console.log(el);
                            if (el === "canopy" || el === "stem" || el === "wire") {
                                validationMaterial(el, model)
                            }
                        })
                    }
                } else {
                    setB("none");
                }
                //
            }

            // modelMtls = { ... };

            const objCombiner = () => {
                var rtn = { ...model.build.data?.materials, ...model.lampshade.data?.materials};
                if (model.build.data) {
                    for (let [i, o] of Object.entries(model.build.data.materials)) {
                        if (model.lampshade.data) {
                            const ls = model.lampshade.data.materials[i];
                            if (ls) {
                                var tmp = [...o, ...ls];
                                rtn[i] = tmp;
                            }
                        }
                    }
                }
                return rtn;
            }
            modelMtls = objCombiner();
            // console.log(modelMtls);




            //callVariation(model,i);
            //function mtlPrice(){
            setVariationTimer(model)
            //setStemTimer(model);

        }
    }, [model]);

    // -------------------------------------------------------------------- Set variation
    function callVariation(zeModel) {
        //console.log(zeModel)
        var i = iframe !== null ? iframe.contentWindow : "";
        if (zeModel?.build.data?.modeloptions.length > 0) {
            var tmpNum = zeModel.build.data.modeloptions.length;
            zeModel.build.data.modeloptions.some((el) => {
                tmpNum--;
                if (el.lampshades.includes(model.lampshade.id)) {
                    //console.log(el.code,model.build.model)
                    //if (currVariation.data?.code !== el.code) {
                    currVariation.data = el;
                    // console.log(el.code);
                    i.postMessage({
                        variation: el.code,
                    })
                    return true
                    //}
                }
                if (tmpNum < 0) { // Reset to first if no model found
                    currVariation.data = model.build.data.modeloptions[0];
                    return true
                }
                return false
            })
        } else {
            currVariation.data = {};
        }
        if (zeModel?.build.data?.stemheights.length > 0) {
            zeModel.build.data.stemheights.some((el) => {
                if (el.lampshade == model.lampshade.id) {
                    console.log(el.stemHeight)
                    i.postMessage({
                        stem: { height: String(el.stemHeight), isCustom: true }
                    });

                    //callVariation(model,i);
                }
            })
        }
    }

    const [variationTimer, setVariationTimer] = useState()

    // -------------------------------------- Tweak
    useEffect(() => {
        //console.log(variationNum);
        if (variationNum == 1) {

            const timeVar = variationNum2 == 0 ? 1000 : 10;
            const timeout = setTimeout(() => {
                //setShow(true)
                callVariation(variationTimer)
                variationNum2 = 1;
            }, timeVar)

            return () => clearTimeout(timeout)
        }

    }, [variationTimer])

    // -----------------------------------------------------------------------------------
    function mtlPrice(zeModel) {
        var mtlArray = [];
        const arrBuild = zeModel.build.data ? zeModel.build.data.mtlsPrice : [];
        const arrLampshade = zeModel.lampshade.data ? zeModel.lampshade.data.mtlsPrice : [];
        mtlArray = mtlArray.concat(arrBuild, arrLampshade);

        mtlArray.map((e) => {
            dc.map((DCe) => {
                if (!DCe.categories.includes(e.type) && DCe.id === e.colorID) {
                    DCe.categories.push(e.type);
                }
            })
        })
    }

    useEffect(() => {
        var i = iframe !== null ? iframe.contentWindow : "";
        if (i !== "") {
            if (filteredID.build.includes(model.build.id)) {
                const zeB = model.build.model === "" ? "none" : model.build.model;
                i.postMessage({
                    build: zeB,
                })
                variationNum = 1;
                //callVariation(model,i);
            } else {
                i.postMessage({
                    build: "none",
                })
            }
            setRequired({ ...required, options: false });
            setPreSubmit(false);
        }
    }, [b]);

    useEffect(() => {
        const req = Object.values(required);
        if (!req.includes(false)) {
            setPreSubmit(false);
        }
    }, [required])


    // Set 3D model in the viewer
    function set3dModel(obj) {
        setModel(obj);
        if (b2 !== obj.build.model) {
            setB2(obj.build.model);
            setFilter("build", obj);
        }
        if (l2 !== obj.lampshade.model) {
            //console.log(l,obj.lampshade.model);  
            setL2(obj.lampshade.model);
            setFilter("lampshade", obj);
        }
    }

    // Check if material is compatible -> set to default if not
    function validationMaterial(type, mod) {
        const isAK = mod.build.model.includes('AK') ? " AK" : "";
        const type2 = type + isAK
        const i = iframe.contentWindow;
        dc.map(function (e) {
            if (e.code === colorMaterials.curr[type]) {
                //console.log(colorMaterials.curr[type]);
                if (type === "wire") {
                    const isBraided = e.code.slice(0, 2) === "T.";
                    //const currBraided = e.code.slice(0, 2) === "T.";
                    const mode = mod.build.data.wireopt;
                    const showWire = mode === 'simple' ? !isBraided : mode === 'braided' ? isBraided : true;
                    //console.log(showWire);
                    if (!showWire) {
                        const goodWire = mode === 'braided' ? "T." + colorMaterials.default[type] : colorMaterials.default[type];
                        colorMaterials.curr[type] = goodWire;
                        colorMaterials.prev[type] = goodWire;
                        i.postMessage({
                            material: { type: type, color: colorMaterials.default[type] },
                        });
                        i.postMessage({
                            acceptMaterials: true,
                        });
                    }
                } else {
                    if (!e.categories.includes(type2)) {

                        colorMaterials.curr[type] = colorMaterials.default[type];
                        colorMaterials.prev[type] = colorMaterials.default[type];
                        i.postMessage({
                            material: { type: type, color: colorMaterials.default[type] },
                        });
                        i.postMessage({
                            acceptMaterials: true,
                        });
                    }
                }
            }
        });
    }

    function setFilter(type, obj) {

        var bA = [];
        var lA = [];
        // If is BUILD
        if (type === "build") {
            if (obj.build.model !== "") {
                if (obj.lampshade.model !== "") {
                    //setValidCombination(true);
                    data.builds.map((el) => {
                        if (el.lampshades.includes(obj.lampshade.id)) {
                            bA.push(el.id);
                        }

                        if (el.id === obj.build.id) {
                            lA = el.lampshades;
                        }
                        return false;
                    })
                } else {
                    //setValidCombination(true);
                    data.builds.map((el) => {
                        bA.push(el.id);
                        if (el.id === obj.build.id) {
                            lA = el.lampshades;
                            //console.log(lA);
                        }
                        return false
                    })
                }
            } else {
                //setValidCombination(false);
                if (obj.lampshade.model !== "") {
                    data.builds.map((el) => {
                        if (el.lampshades.includes(obj.lampshade.id)) {
                            bA.push(el.id);
                            //return false;
                        }
                        return false;
                    })
                } else {
                    data.builds.map((el) => {
                        bA.push(el.id);
                        return false;
                    })
                }
                data.lampshades.map((el) => {
                    lA.push(el.id);
                    return false;
                })
            }
            // if is LAMPSHADE
        } else {
            if (obj.lampshade.model !== "") {
                data.builds.map((el) => {
                    if (el.lampshades.includes(obj.lampshade.id)) {
                        bA.push(el.id);
                        //return false;
                    }
                    if (obj.build.model !== "") {
                        if (el.id === obj.build.id) {
                            lA = el.lampshades;
                        }
                    }
                    return false;
                })
                if (obj.build.model === "") {
                    data.lampshades.map((el) => {
                        lA.push(el.id);
                        return false;
                    })
                }
            } else {
                if (obj.build.model !== "") {
                    data.builds.map((el) => {
                        bA.push(el.id);
                        if (el.id === obj.build.id) {
                            lA = el.lampshades;
                            //console.log(lA);
                        }
                        return false;
                    })
                } else {
                    data.builds.map((el) => {
                        bA.push(el.id);
                        return false;
                    })
                    data.lampshades.map((el) => {
                        lA.push(el.id);
                        return false;
                    })
                }

            }
        }
        setFilteredID({ ...filteredID, build: bA, lampshade: lA });
        //console.log(lA);
        return;
    }

    /*useEffect(() => {
      if(model.build.id!==null){
        buildRef.current[model.build.id].scrollIntoView({ block: 'center',  behavior: 'smooth' });
        console.log("Build");
      }
      if(model.lampshade.id!==null){
        lampshadeRef.current[model.lampshade.id].scrollIntoView({ block: 'center',  behavior: 'smooth' });
        console.log("Lampshade");
      }
    },[filteredID]);*/

    function validCombinationF() {
        if (model.build.model !== "") {
            if (model.build.data.lampshades.includes(model.lampshade.id) || model.lampshade.id === null) {
                setValidCombination(true);
            } else {
                setValidCombination(false);
            }
            //console.log(model.build);   
        } else {
            setValidCombination(false);
        }
    }

    function closeSideOpt(type, close = false) {
        const i = iframe.contentWindow;
        const rstBtn = document.getElementById("reset");

        if (currType === type) {
            setSideOpened(null);
            setCurrType()
        } else {
            setCurrType(type)
        }
        if (close) {
            //if(type === null){
            setSideOpened(null);
            //}            
        }
        switch (sideOpened) {
            case "choose":
                prevBG.color = currentBG.color;
                prevBG.hex = currentBG.hex;
                break;
            case "palette":
                if (prevPalette.id !== currentPalette.id) {
                    rstBtn.style.display = "block"
                    prevPalette.id = currentPalette.id;
                    prevPalette.code = currentPalette.code;

                    //save palette to current color
                    colorMaterials.curr.exterior = currentPalette.code[3];
                    colorMaterials.curr.exterior2 = currentPalette.code[3];
                    colorMaterials.curr.exterior3 = currentPalette.code[3];
                    colorMaterials.curr.interior = currentPalette.code[2];
                    colorMaterials.curr.canopy = currentPalette.code[1];
                    colorMaterials.curr.stem = currentPalette.code[0];
                    //save palette to previous color
                    colorMaterials.prev.exterior = currentPalette.code[3];
                    colorMaterials.prev.exterior2 = currentPalette.code[3];
                    colorMaterials.prev.exterior3 = currentPalette.code[3];
                    colorMaterials.prev.interior = currentPalette.code[2];
                    colorMaterials.prev.canopy = currentPalette.code[1];
                    colorMaterials.prev.stem = currentPalette.code[0];
                    i.postMessage({
                        acceptMaterials: true,
                    });
                }

                break;
            // Customize all material panel
            case "individual":
                rstBtn.style.display = "block"
                currentPalette.id = null;
                currentPalette.code = null;
                prevPalette.id = currentPalette.id;
                prevPalette.code = currentPalette.code;
                i.postMessage({
                    acceptMaterials: true,
                });
                Object.keys(colorMaterials.curr).map(toggle => {
                    return colorMaterials.prev[toggle] = colorMaterials.curr[toggle];
                })
                break;
            case "options":
                prevCanopy.data = currCanopy.data;
                prevStem.data = currStem.data;
                prevVariation.data = currVariation.data;

                /*if (model.build.data.modeloptions.length > 0) {
                    i.postMessage({
                        build: prevVariation.data.code,
                    });
                }*/

                i.postMessage({
                    acceptMaterials: true,
                });
                break;
        }
    }

    // -------------------------------------------------------------------------------- Code part

    const [onMakingModel, setOnMakingModel] = useState(urlCode !== null);

    useEffect(() => {
        if (setPurl === 1) {
            var id = 0;
            const codeArray = urlCode.slice(0, urlCode.length).match(/.{1,2}/g);
            const interval = setInterval(() => {
                // You'd want an exit condition here
                setModelFromCode(codeArray[id], id, false, codeArray[0]);
                //console.log(codeArray[id],id);
                id++;
                if (id == codeArray.length) {
                    setModelFromCode(0, 0, true);
                    clearInterval(interval);
                    setOnMakingModel(false);
                }
            }, 50);

            return () => clearInterval(interval);
        }
    }, [setPurl]);

    const lampshadeRef = useRef([]);
    const buildRef = useRef([]);

    function setModelFromCode(el, i, end = false, varia = 0) {
        var id = parseInt(el, 16); // convert HEX to ID      
        const ifr = iframe.contentWindow;
        const tmpVariation = parseInt(varia, 16);
        if (!end) {
            if (i === 0) {
                buildRef.current[id]?.click();
                //tmpVariation = id;
            } else if (i === 1) {
                //console.log(lampshadeRef)
                lampshadeRef.current[id]?.click();
            } else if (i === 2) {
                //console.log(currCanopy.data);
                if (id !== 0) {
                    currCanopy.data = findPartByID('canopy', id);
                    ifr.postMessage({
                        canopy: { type: currCanopy.data.code },
                    });
                }
            } else if (i === 3) {
                //console.log(currStem.data);
                if (id !== 0) {
                    currStem.data = findPartByID('stem', id);
                    ifr.postMessage({
                        stem: { height: currStem.data.code },
                    });
                }
            } else if (i === 4) {
                //console.log(currStem.data);
                if (id !== 0) {
                    currVariation.data = findPartByID('variation', id, tmpVariation);
                    console.log(currVariation.data);
                    ifr.postMessage({
                        variation: currVariation.data.code,
                    });
                }
            } else {
                const el = Object.keys(colorMaterials.default)[i - 5];
                const color = findPartByID('colors', id);
                //console.log(el + " -> " + id);
                if (color !== null) {
                    if (colorMaterials.default[el] !== color) {
                        colorMaterials.curr[el] = color.code;
                        colorMaterials.prev[el] = color.code;
                        //console.log(findPartByID('colors',id),);
                        ifr.postMessage({
                            material: { type: el, color: color.code },
                        });
                    }
                } else {
                    console.log('The color for "' + el + '" component doesn\'t exist!')
                }
            }
        } else {
            ifr.postMessage({
                acceptMaterials: true,
            });
            document.getElementById("reset").style.display = "block";
        }
    }

    /* useEffect(() => {
       console.log(buildRef);
     }, [buildRef]);
     useEffect(() => {
       console.log(lampshadeRef);
     }, [lampshadeRef]);*/

    function d2h(d) {
        var s = (+d).toString(16);
        if (s.length < 2) {
            s = '0' + s;
        }
        return s;
    }
    function setCodeFromModel() {
        /*var tmpCode = 'gghhiijjkkllmmnnooppqqrrsstt';
        const codeArray = tmpCode.match(/.{1,2}/g);*/
        const arr = code;
        dc = structuredClone(data.colors);
        //console.log(model);
        code.map((el, i) => {
            if (i === 0) {
                const b = urlMode.includes('product') ? productID.build : model.build.id;
                if (b !== null) {
                    arr[0] = d2h(b);
                    //console.log(d2h(model.build.id));
                } else {
                    arr[0] = '00';
                }
            } else if (i === 1) {
                const l = urlMode.includes('product') ? productID.lampshade : model.lampshade.id;
                if (l !== null) {
                    arr[1] = d2h(l);
                } else {
                    arr[1] = '00';
                }
            } else if (i === 2) {
                const c = currCanopy.data?.id;
                if (c !== undefined) {
                    arr[2] = d2h(c);
                } else {
                    arr[2] = '00';
                }
            } else if (i === 3) {
                const s = currStem.data?.id;
                if (s !== undefined) {
                    arr[3] = d2h(s);
                } else {
                    arr[3] = '00';
                }
            } else if (i === 4) {
                var s = undefined;
                model.build.data?.modeloptions.some((el, i) => {
                    // console.log(el, i)
                    if (el.code === currVariation.data.code) {
                        s = i + 1
                        return true
                    }
                    return false
                })
                if (s !== undefined) {
                    arr[4] = d2h(s);
                } else {
                    arr[4] = '00';
                }
            } else {
                const el = Object.keys(colorMaterials.default)[i - 5];
                if (colorMaterials.curr[el] === undefined) {
                    arr[i] = d2h(mtlCodeToId(colorMaterials.default[el]));
                } else {
                    arr[i] = d2h(mtlCodeToId(colorMaterials.curr[el]));
                }
            }
        });
        setCode(arr);
    }

    function mtlCodeToId(mtl) {
        var mtlID = null;
        dc.some((el) => {
            if (el.code === mtl) {
                mtlID = el.id;
                return true;
            }
        })
        return mtlID;
    }

    function findPartByID(part, id, variationID = "") {
        var val = null;
        if (part === "variation") {
            data.builds.some((el) => {
                // console.log(el.id, variationID);
                if (el.id == variationID) {
                    val = el.modeloptions[id - 1];
                    //console.log(val);
                    return true
                }
                return false
            })
            //val = model.build.data.modeloptions[id-1];
        } else {
            data[part].some((el) => {
                if (el.id === id) {
                    val = el;
                    return true;
                }
                return false;
            });
        }
        return val;
    }
    function findPartByCode(part, code) {
        var val = null;
        data[part].some((el) => {
            if (el.code === code) {
                val = el;
                return true;
            }
            return false;
        });
        return val;
    }

    const [mobileMenu, setMobileMenu] = useState(false);
    const componentActive = mobileMenu ? 'active' : '';
    const resultActive = mobileMenu ? '' : 'active';

    const setMobileMenuF = function (state) {
        closeSideOpt("", true);
        setMobileMenu(state);
    }

    var isToggled = sideOpened === null ? "toggled" : "";
    var showTopOption = model.build.model !== "" || model.lampshade.model !== "" ? "" : "toggled";



    //OnBoarding
    var firstVisit = localStorage.getItem("firstVisit") === null ? urlCode === null ? urlMode === '' ? true : false : false : false;
    //console.log(firstVisit);

    //const [onBoarding, setOnBoarding] = useState(firstVisit);
    const [onBoardingVisible, setOnBoardingVisible] = useState(urlCode === null);

    // Transition popups

    const [envVisible, setEnvVisible] = useState(false);
    const [pdfVisible, setPDFVisible] = useState(false);
    const [cartVisible, setCartVisible] = useState(false);

    const [menuEnv3D, setMenuEnv3D] = useState(true);


    // Is mobile
    const ua = navigator.userAgent.toLowerCase(), platform = navigator.platform.toLowerCase();
    const platformName = ua.match(/ip(?:ad|od|hone)/) ? 'ios' : (ua.match(/(?:webos|android)/) || platform.match(/mac|win|linux/) || ['other'])[0],
        isMobile = /ios|android|webos/.test(platformName);

    const [mobilePopup, setMobilePopup] = useState(false);
    //console.log(isMobile)

    const mobileRequired = (!required.build || !required.lampshade) && preSubmit ? "required" : "";

    const webLang = t('lang') === "en" ? "/fr" : "";
    //console.log("RELOADEDD");
    return (
        <LocaleContext.Provider value={{ locale, setLocale }}>
            {urlMode.includes('product') ?
                <>
                    {(loadScreen || loadMtlScreen) && <LoadingScreen className="fullbg" />}
                    {Object.keys(data).length > 0 && <iframe onLoad={() => initFilters(true)} id="productIframe" title="3D product App" src={"./app/" + appVersion + "/index.html?guide=once"} loading="lazy" ></iframe>}
                    {Object.keys(data).length > 0 && <PDF isProduct={true} code={code} model={model} data={data} pdfVisible={pdfVisible} setPDFVisible={setPDFVisible} dataColor={dc} setBimg={setBimg} bImg={bImg} sImg={sImg} currModel={model} mtl={mtl} />}
                </>
                :
                urlParams.get('report') === "true" ?
                    <>
                        {Object.keys(data).length > 0 && <Possibilities
                            data={data}
                        />}
                    </>
                    :
                    <>
                        {onMakingModel && <LoadingScreen className="full" />}
                        {isMobile && mobilePopup &&
                            <div id="mobileAlert">
                                <div>
                                    <span onClick={() => setMobilePopup(false)}>X</span>
                                    <div className="flexMobile">
                                        <div>
                                            <img src="./images/monitor.png" />
                                            <p>{t("mobileMessage")}</p>
                                        </div>
                                        <button onClick={() => setMobilePopup(false)}>{t("continueMobile")}</button>
                                    </div>
                                </div>
                            </div>
                        }
                        {err && <div id='errorPage'><div><img src="../../images/error.png" /><p>{t('errPage')}</p></div></div>}
                        {Object.keys(data).length > 0 && <Env3D
                            model={model}
                            iframe={iframe}
                            currentBGid={currentBGid}
                            setCurrentBGid={setCurrentBGid}
                            data={data}
                            envVisible={envVisible}
                            setEnvVisible={setEnvVisible} />
                        }
                        {Object.keys(data).length > 0 && <PDF
                            code={code}
                            model={model}
                            data={data}
                            pdfVisible={pdfVisible}
                            setPDFVisible={setPDFVisible}
                            dataColor={dc}
                            setBimg={setBimg}
                            bImg={bImg}
                            sImg={sImg}
                            currModel={model}
                            mtl={mtl} />
                        }
                        {Object.keys(data).length > 0 && <Cart
                            data={data}
                            currModel={model}
                            code={code}
                            cartVisible={cartVisible}
                            setCartVisible={setCartVisible}
                            cart={cart} />
                        }
                        <TopBar
                            onBoarding={onBoardingVisible}
                            setOnBoardingV={setOnBoardingVisible}
                            setCurrentBGid={setCurrentBGid}
                            currentBGid={currentBGid}
                            setEnvVisible={setEnvVisible}
                            print={printPage}
                            urlShort={urlShort}
                        />

                        {(Object.keys(data).length > 0 && iframe !== null && onBoardingVisible) && <OnBoarding
                            onBoarding={onBoardingVisible}
                            setRequired={setRequired}
                            required={required}
                            setOnBoardingV={setOnBoardingVisible}
                            setSideOpened={setSideOpened}
                            model={model}
                            lr={lampshadeRef}
                            br={buildRef}
                            data={data}
                            isMobile={isMobile} />
                        }
                        <div className="noprint" id="mainbody">
                            <div id="sideoptParent" className={isToggled}>
                                {Object.keys(data).length > 0 && <SideOpt
                                    modelMtls={modelMtls}
                                    forceUpdate={forceUpdate}
                                    setForceUpdate={setForceUpdate}
                                    setCurrType={setCurrType}
                                    currModel={model}
                                    data={data}
                                    modelType={mtl}
                                    iframe={iframe}
                                    opt={sideOpened}
                                    openSide={setSideOpened}
                                    dc={dc} />
                                }
                            </div>
                            {Object.keys(data).length > 0 && <SideMenu
                                preSubmit={preSubmit}
                                setRequired={setRequired}
                                isRequired={required}
                                currentBGid={currentBGid}
                                mobile={resultActive}
                                setLoadScreen={setLoadScreen}
                                loadScreen={loadScreen}
                                filtered={filteredID}
                                iframe={iframe}
                                data={data}
                                parentModel={set3dModel}
                                currModel={model}
                                lampshadeRef={lampshadeRef}
                                buildRef={buildRef}
                                resetAll={resetAll}
                                isResetAll={isResetAll}
                                setIsResetAll={setIsResetAll}
                            />
                            }
                            <div id="rightBody">
                                {(loadScreen || loadMtlScreen) && <LoadingScreen className="fullbg" />}
                                {!validCombination && <div id="invalidSelection"><p>{t("pleaseSelect")}</p></div>}
                                <a data-lang={t("lang-logo")} target="blank" id="poweredByED" href={"https://www.edgedimension.com" + webLang}></a>
                                {Object.keys(data).length > 0 && <iframe onLoad={() => initFilters()} id="appIframe" title="3D App" src={"./app/" + appVersion + "/index.html?guide=always"} loading="lazy" ></iframe>}
                                {Object.keys(data).length > 0 && <OptionTop
                                    currentBGid={currentBGid}
                                    forceUpdate={forceUpdate}
                                    setForceUpdate={setForceUpdate}
                                    preSubmit={preSubmit}
                                    setRequired={setRequired}
                                    isRequired={required}
                                    iframe={iframe}
                                    canShow={showTopOption}
                                    parentModel={set3dModel}
                                    currModel={model}
                                    opt={sideOpened}
                                    openSide={setSideOpened}
                                    closeSideOpt={closeSideOpt}
                                    restBtnRef={restBtnRef} />
                                }
                                {Object.keys(data).length > 0 && <BottomBar
                                    setPreSubmit={setPreSubmit}
                                    isRequired={required}
                                    makeCode={setCodeFromModel}
                                    setCartVisible={setCartVisible}
                                    mtl={mtl} cart={cart}
                                    setCart={setCart}
                                    filtered={filteredID}
                                    data={data}
                                    currModel={model} />
                                }
                                {Object.keys(data).length > 0 && <RightOpt
                                    model={model}
                                    data={data}
                                    iframe={iframe}
                                    setCurrentBGid={setCurrentBGid}
                                    currentBGid={currentBGid}
                                    setEnvVisible={setEnvVisible}
                                    id='rightOptions'
                                    menuEnv3D={menuEnv3D}
                                    setMenuEnv3D={setMenuEnv3D}
                                    print={printPage} />
                                }
                                {currentBGid !== null &&
                                    <div id='envMeasures'>
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td>{t("ceilingH") + " : "}</td>
                                                    <td>{bgValues[currentBGid - 1].ceilingH + '\''}</td>
                                                </tr>
                                                {bgValues[currentBGid - 1].type !== "heightOnly" && <tr>
                                                    <td>{(bgValues[currentBGid - 1].type === "island" ? t("islandW") : t("tableW")) + " : "}</td>
                                                    <td>{bgValues[currentBGid - 1].islandW + '\''}</td>
                                                </tr>}
                                            </tbody>
                                        </table>
                                    </div>
                                }
                            </div>
                            <div id="bottomMobile">
                                <div onClick={() => setMobileMenuF(true)} className={"bottomButton " + componentActive + " " + mobileRequired}><span></span><p>{t('components')}</p></div>
                                <div onClick={() => setMobileMenuF(false)} className={"bottomButton " + resultActive}><span></span><p>{t('result')}</p></div>
                            </div>
                        </div>
                    </>
            }
        </LocaleContext.Provider>
    );
}

export default App;
